import { CtaListProps } from "design-system/components/primitives/cta-list/cta-list";
import { createCtaLinkProps } from "./cta-link.props";
import { EntryRef } from "frontend/contentful/schema/sys";
import {
  LinkEntry,
  SocialMediaLinkEntry,
} from "frontend/contentful/schema/primitives";

export function createCtaListProps(
  links?: Array<EntryRef<LinkEntry | SocialMediaLinkEntry>>,
): CtaListProps | undefined {
  if (!Array.isArray(links)) return undefined;

  const props: CtaListProps = { items: [] };

  links.forEach((link) => {
    const ctaLinkProps = createCtaLinkProps(link);
    if (ctaLinkProps) props.items?.push(ctaLinkProps);
  });

  return props;
}
