import { ContentfulComponentProps } from "./create-contentful-component";
import { ContentfulEntry, EntryRef } from "frontend/contentful/schema/sys";
import { useContentful } from "frontend/hooks/use-contentful";

type ContentfulTransformer<E extends ContentfulEntry, P, O> = (
  props: ContentfulComponentProps<E, O>,
) => P | undefined;

export function createContentfulProps<E extends ContentfulEntry, P, O = void>(
  transformer: ContentfulTransformer<E, P, O>,
) {
  const useContentfulPropsTransformer = (
    ref?: EntryRef<E>,
    options?: O,
  ): P | undefined => {
    const { getEntry, createEditAttributes } = useContentful();

    const entry = getEntry(ref);

    if (!entry?.fields || !entry?.metadata || !entry?.sys.id) return undefined;

    try {
      return transformer({ entry, options, createEditAttributes });
    } catch (e) {
      console.error(e);
      return undefined;
    }
  };

  return useContentfulPropsTransformer;
}
