import { ComponentHeaderEntry } from "frontend/contentful/schema/primitives";
import { ComponentHeaderProps } from "design-system/components/primitives/component-header/component-header";
import { RenderContentfulRichText } from "frontend/contentful/components/utils/render-contentful-rich-text";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { useContentful } from "frontend/hooks/use-contentful";

export const createComponentHeaderProps = createContentfulProps<
  ComponentHeaderEntry,
  ComponentHeaderProps
>(({ entry, createEditAttributes }) => {
  const { getEntryUrl, getEntry } = useContentful();

  const link = getEntry(entry.fields?.link);

  return {
    title: entry.fields?.title,
    link: getEntryUrl(link),
    linkText: link?.fields.text,
    ariaLabel: link?.fields.ariaLabel,
    isSmall: entry.fields?.size === "small",
    description: entry.fields?.description ? (
      <RenderContentfulRichText document={entry.fields?.description} />
    ) : undefined,
    borderPosition: entry.fields?.borderPosition || undefined,
    HeadingLevel: entry.fields?.headingLevel || undefined,

    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      description: createEditAttributes({ entry, fieldId: "description" }),
      linkText: createEditAttributes({
        entry: entry.fields.link,
        fieldId: "text",
      }),
    },
  };
});
