import "./data-visualization-embed.scss";

import cn from "clsx";
import { Alignment } from "design-system/types/types";
import {
  ComponentHeader,
  ComponentHeaderProps,
} from "design-system/components/primitives/component-header/component-header";

export interface DataVisualizationEmbedProps {
  header?: ComponentHeaderProps;
  embedSourceUrl?: string;
  embedSourceTitle?: string;
  height?: string;
  align?: Alignment;
}

export function DataVisualizationEmbed({
  header,
  embedSourceUrl,
  embedSourceTitle,
  height,
  align = "center",
}: DataVisualizationEmbedProps) {
  const wrapperClass = cn(
    `hbs-global-align-${align}`,
    "hbs-component--data-visualization-embed",
  );

  return (
    <div className={wrapperClass} data-region="cb__data-visualization-embed">
      <div className="hbs-data-visualization-embed">
        {header && (
          <div className="hbs-data-visualization-embed__header">
            <ComponentHeader {...header} />
          </div>
        )}
        {embedSourceUrl ? (
          <iframe
            src={embedSourceUrl}
            width="100%"
            height={height || 400}
            title={embedSourceTitle}
          />
        ) : null}
      </div>
    </div>
  );
}
