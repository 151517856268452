import {
  OrganizationEntry,
  PersonEntry,
} from "frontend/contentful/schema/semantics";
import { createContentfulProps } from "../../lib/create-contentful-props";
import { SocialMediaLinksProps } from "design-system/components/primitives/social-media-links/social-media-links";
import {
  SocialIconName,
  SocialMediaIconProps,
} from "design-system/components/primitives/social-media-links/social-media-icon";
import { useContentful } from "frontend/hooks/use-contentful";

const stringToCamelCase = (str?: string) => {
  if (!str) return undefined;

  return str
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const createSocialMediaLinksProps = createContentfulProps<
  OrganizationEntry | PersonEntry,
  SocialMediaLinksProps
>(({ entry, createEditAttributes }) => {
  const { getEntries } = useContentful();

  const props = {
    links: [] as Array<SocialMediaIconProps>,
  } satisfies SocialMediaLinksProps;

  if (!entry?.fields) return props;

  getEntries(entry.fields.socialMediaLink)?.forEach((item) => {
    props.links.push({
      href: item.fields.url,
      title: "HBS " + item.fields.socialMedia,
      icon: stringToCamelCase(item.fields.socialMedia) as SocialIconName,
      ariaLabel: item.fields.ariaLabel,
      editAttributes: createEditAttributes({ entry: item, fieldId: "url" }),
    });
  });

  return props;
});
