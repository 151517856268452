import { LinkProps } from "design-system/components/primitives/link/link";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";

import { LinkEntry } from "frontend/contentful/schema/primitives";
import { useContentful } from "frontend/hooks/use-contentful";

export const createLinkProps = createContentfulProps<LinkEntry, LinkProps>(
  ({ entry, createEditAttributes }) => {
    const { getEntryUrl } = useContentful();

    return {
      href: getEntryUrl(entry),
      "aria-label": entry.fields.ariaLabel,
      children: entry.fields.text,
      editAttributes: createEditAttributes({ entry, fieldId: "text" }),
    };
  },
);
