import { TooltipProps } from "design-system/components/primitives/tooltip/tooltip";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { KnowledgeGraphData, KnowledgeGraphItem } from "frontend/types";
import { useFetch } from "frontend/hooks/use-fetch";

import { TooltipEntry } from "frontend/contentful/schema/primitives";
import { Link } from "design-system/components/primitives/link/link";

export const createTooltipProps = createContentfulProps<
  TooltipEntry,
  TooltipProps
>(({ entry, createEditAttributes }) => {
  if (!entry.fields.uri) {
    return;
  }

  const { data } = useFetch<KnowledgeGraphData>({
    url: "/api/fetch/tooltip-graph",
    params: { uri: entry.fields.uri, type: "Companies" },
  });

  if (!data) {
    return;
  }

  const tooltipContent = (
    <ul>
      {data.publications.map((item: KnowledgeGraphItem, i: number) => (
        <li key={i}>
          <Link href={item.link} target={item.target}>
            {item.text}
          </Link>
        </li>
      ))}
    </ul>
  );

  return {
    title: entry.fields.title,
    children: entry.fields.highlightedText ?? data.search,
    text: tooltipContent,
    editAttributes: {
      title: createEditAttributes({ entry, fieldId: "title" }),
      children: createEditAttributes({ entry, fieldId: "highlightedText" }),
    },
  };
});
