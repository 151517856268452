import cn from "clsx";
import { Alignment } from "design-system/types/types";
import { useEffect } from "react";
import { loadScript } from "design-system/hooks/use-script";

export interface FlourishEmbedProps {
  embedSourceUrl: string;
  align?: Alignment;
}

export function FlourishEmbed({
  embedSourceUrl,
  align = "center",
}: FlourishEmbedProps) {
  const wrapperClass = cn(
    `hbs-global-align-${align}`,
    "hbs-component--flourish-embed",
  );

  let extractedChartId: string | undefined;
  let type: "story" | "visualisation" = "visualisation";

  const visualisationMatch = embedSourceUrl.match(/\/visualisation\/(\d+)/);
  if (visualisationMatch) {
    extractedChartId = visualisationMatch[1];
    type = "visualisation";
  } else {
    const storyMatch = embedSourceUrl.match(/\/story\/(\d+)/);
    if (storyMatch) {
      extractedChartId = storyMatch[1];
      type = "story";
    }
  }

  useEffect(() => {
    if (extractedChartId) {
      loadScript("https://public.flourish.studio/resources/embed.js", {
        defer: true,
      });
    } else {
      console.warn("Chart ID not found in the URL");
    }
  }, [extractedChartId]);

  return (
    <div className={wrapperClass} data-region="cb__flourish-embed">
      <div className="hbs-flourish-embed">
        <div
          className="flourish-embed flourish-chart"
          data-src={`${type}/${extractedChartId}?2123658`}
        >
          <noscript>
            {/* eslint-disable-next-line react/forbid-elements */}
            <img
              src={`https://public.flourish.studio/${type}/${extractedChartId}/thumbnail`}
              width="100%"
              alt="chart visualization"
            />
          </noscript>
        </div>
      </div>
    </div>
  );
}
